
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'
import Config from '@/config'
import { httpRequest } from '@/plugins'

@Options({
  components: {
    PagePlugin,
    CustomDialog
  }
})
export default class confirmed extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }
  tb = {
    page: 1,
    pageSize: 10
  }
  pageChange({ currentPage, pageSize }) {
    this.tb.page = currentPage
    this.tb.pageSize = pageSize
  }
  tabel1_edit_dialog = {
    title: '审核',
    open: false
  }
  tabel1_edit_data = {
    id: 0,
    status: 2,
    return_reason: ''
  }
  t_search_data = {
    start_time: '',
    end_time: '',
    name: '',
    status: [] as any[],
    _status: '' as number | string
  }
  table = {
    rows: [] as any[],
    page: 1,
    pageSize: 10,
    total: 0
  }
  rule_t_search_data = {}
  status_657 = new Map<any, string>([
    // [0, '草稿'],
    // [1, '待成员确认'],
    [2, '待指派组长'],
    [3, '待分配专家'],
    [4, '待专家评'],
    [5, '待组长审核'],
    [6, '审核通过'],
    [7, '审核不通过'],
    [8, '待评估机构确认'],
    [9, '成员退回'],
    [10, '评估机构退回']
  ])
  type_654 = new Map<any, string>([
    [1, '发明'],
    [2, '实用新型'],
    [3, '外观设计']
  ])
  type_676 = new Map<any, string>([
    [1, '发明'],
    [2, '实用新型'],
    [3, '外观设计']
  ])
  type_686 = new Map<any, string>([
    [1, '基础研究'],
    [2, '应用研究'],
    [3, '技术开发与产业化']
  ])
  type_696 = new Map<any, string>([
    [1, '否'],
    [2, '是']
  ])
  getTableRows(offset = 0, length = 10) {
    const params = { offset: 0, length: 10 }
    if (offset) params.offset = offset
    if (length) params.length = length
    if (this.t_search_data._status !== '') { this.t_search_data.status = [this.t_search_data._status] }
    httpRequest
      .post('api/v1/patent/getConfirmList', {
        ...this.t_search_data,
        ...params,
        patent_type: 2
      })
      .then((res) => {
        this.table.rows = res.data.data
        this.table.rows.forEach((item) => {
          if (Utils.isJSON(item.inventor)) {
            // 发明人列表
            item.inventor_names = JSON.parse(item.inventor)
              .map((it) => it.name)
              .join(',')
          }
        })
        this.table.total = res.data.total
        console.log('res :>> ', res)
      })
  }
  tabel1_edit_cancle() {
    this.tabel1_edit_dialog.open = false
  }
  // 审核
  t_edit_init(row) {
    this.tabel1_edit_data.id = row.id
    this.tabel1_edit_dialog.open = true
  }
  tabel1_edit_ok() {
    if (
      this.tabel1_edit_data.status === 10
      && !this.tabel1_edit_data.return_reason
    ) {
      return this.$message.error('请填写拒绝原因')
    }
    httpRequest
      .post('api/v1/patent/confirmAudit', {
        id: this.tabel1_edit_data.id,
        status: this.tabel1_edit_data.status,
        return_reason: this.tabel1_edit_data.return_reason
      })
      .then((res: any) => {
        if (res.code === '200') {
          this.$message.success(res.message)
          this.tabel1_edit_dialog.open = false
          this.getTableRows(
            (this.tb.page - 1) * this.tb.pageSize,
            this.tb.pageSize
          )
        } else this.$message.error(res.message)
      })
  }
  t_detail_dialog = {
    open: false,
    title: '详情'
  }
  t_detail_data = {
    person_info: {
      dept: '', // 部门单位
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      inventor: [] as any[]
    },
    base_info: {
      name: '',
      type: 0,
      number: '',
      industry_involved: [],
      related_industry: [],
      invention_member: '',
      patentee: '',
      digest: '', // 摘要
      claim_rights_file: '' as any,
      digest_file: '' as any,
      specification_file: '' as any,
      claim_rights: '', // 权利要求书
      specification: '', // 说明书
      result_type: '' as string | number,
      result_form: '',
      _result_form: '',
      has_others: 1 as string | number,
      others_msg: [] as any
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      expect: [] as any,
      max_price: 0,
      min_price: 0,
      permit: '',
      permit_select: '',
      permit_amount: 0
    },
    self_info: [] as any[]
  }
  getIndustry: any[] = []
  getIndustry2: any[] = []
  WeightList: any[] = []
  t_detail_init(row) {
    this.get_getIndustry(row.id)
    this.get_getIndustry2(row.id)
    this.get_WeightList(row.id)
    Api.http_my_patenttget4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_detail_dialog.open = true
        // console.log('.....................', res.data);
        this.t_detail_data.person_info = res.data.person_info
        this.t_detail_data.base_info = res.data.base_info
        this.t_detail_data.supplement = res.data.supplement
        this.t_detail_data.conversion_situation = res.data.conversion_situation
        this.t_detail_data.self_info = res.data.self_info
        this.setDialogName(this.t_detail_dialog.title)
        if (Utils.isJSON(this.t_detail_data.base_info.others_msg)) {
          this.t_detail_data.base_info.others_msg = JSON.parse(
            this.t_detail_data.base_info.others_msg
          )
        }
        if (Utils.isJSON(this.t_detail_data.conversion_situation.expect)) {
          this.t_detail_data.conversion_situation.expect = JSON.parse(
            this.t_detail_data.conversion_situation.expect
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.claim_rights_file)) {
          this.t_detail_data.base_info.claim_rights_file = JSON.parse(
            this.t_detail_data.base_info.claim_rights_file
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.digest_file)) {
          this.t_detail_data.base_info.digest_file = JSON.parse(
            this.t_detail_data.base_info.digest_file
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.specification_file)) {
          this.t_detail_data.base_info.specification_file = JSON.parse(
            this.t_detail_data.base_info.specification_file
          )
        }
      }
    })
  }

  t_detail_cancle() {
    this.t_detail_dialog.open = false
    this.removeDialogName()
  }

  t_detail_ok() {
    this.t_detail_dialog.open = false
  }
  get_getIndustry(id = -1) {
    Api.http_my_patenttgetIndustry0({ id: id, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.getIndustry = res.data.data
        }
      }
    )
  }
  get_getIndustry2(id = -1) {
    Api.http_my_patenttgetIndustry20({ id: id, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') this.$message.error(res.message)
        else this.getIndustry2 = res.data.data
      }
    )
  }
  get_WeightList(id = -1) {
    Api.http_my_patenttWeightList0({
      id: id,
      offset: 0,
      length: 999,
      type_id: 6
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        // console.log('55555555555', res)
        this.WeightList = res.data.data
      }
    })
  }
  created() {
    this.getTableRows()
  }
}
